var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._t("default", null, {
    "push": _vm.push,
    "remove": _vm.remove
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }